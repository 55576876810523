import React from 'react';
import styled from 'styled-components'
// import ShowTermsAndConditions from '../../features/webshop/components/ShowTermsAndConditions';

import { InfoIconCard } from '../../ui'
import { media } from '../../utils/media';

const IconCardWrapper = styled.div`
  width:100%;
  padding:20px;
  background-color: ${({ theme, $isMini }) => $isMini && theme.colors.NEUTRAL_LIGHT};
  /* Grid Fallback */
  display: ${({ $showGrid }) => !$showGrid ? 'flex' : 'grid'};
  flex-wrap: ${({ $showGrid }) => !$showGrid ? 'nowrap' : 'wrap'};
  grid-template-columns: ${({ $showGrid }) => $showGrid ? 'repeat(3, 1fr)' : null};
  grid-gap: ${({ $showGrid, $isMini }) => $showGrid ? !$isMini ? '4rem 9rem' : '1rem 1rem' : null};
  margin: ${({ $isMini }) => $isMini ? '3rem 0 0.5rem 0' : '0 0 0.5rem 0'};
  justify-content: ${({ $isMini }) => !$isMini ? 'center' : 'flex-start'};
  gap: ${({ $showGrid }) => !$showGrid ? '8rem' : null};

  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${({ $isMini }) => !$isMini ? '3rem 5rem' : '2rem 1rem'};
    justify-content: center;
  }

  ${media.lessThan('phablet')} {
    grid-gap: ${({ $isMini }) => !$isMini ? '3rem 4rem' : '2rem 0'};
    flex-wrap: wrap;
  }
`;

const InfoIconSection = ({ items, size, showterms }) => {

  const isMini = size === 'small' || size === 'x-small'

  return (
    <>
      {!!items.length &&
        <IconCardWrapper $showGrid={items.length > 2} $isMini={isMini}>
          {items.map((item, i) =>
            <InfoIconCard iconInfo={item} key={i} size={size} />
          )}
        </IconCardWrapper>
      }
    </>
  );
};

export default InfoIconSection;
