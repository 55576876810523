import React, { useEffect, useState } from 'react';
import {  Button } from '..'
import { Popover } from '@mui/material';

const Dropdown = ({ items, label, variant, bgcolor, iconName, position='top', children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(null);

  useEffect(()=>{
    const isOpen = Boolean(anchorEl)
    setId( isOpen? 'simple-popover' : undefined);
    setOpen(isOpen)
  }, [anchorEl])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button $variant={variant} $bgcolor={bgcolor} icon={iconName} onClick={handleClick}>{label}</Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
       
        anchorOrigin={{
          vertical: {position},
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: position === 'top' ? 'bottom': 'top',
          horizontal: 'left',
        }}
      >
        {children}
    </Popover>
  </div>
  );
};

export default Dropdown;
