import React, { useCallback } from 'react';
import { Textinput, Flex } from '../../../ui';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

const CoursePrice = ({ price, setPrice }) => {
  const { t } = useTranslation();

  const addVatToPrice = (price, newValue = 0) => {

    const setVAT = (price.gross / price.net)
    const VAT = setVAT ? setVAT : 1.25

    const gross = +newValue * VAT;
    return {
      net: newValue,
      gross,
      discounted: gross
    };
  }

  const handlePriceChange = useCallback(debounce((e) => {
    const newPrice = addVatToPrice(price, e.target.value);
    setPrice(newPrice);

  }, 800), [price]);

  return (
    <Flex gap='20'>
      <Textinput
        label={t('globals.priceExcVat')}
        propName={'price'}
        defaultValue={price.net}
        onChange={handlePriceChange}
      />
      <Textinput
        label={t('globals.priceIncVat')}
        propName={'price'}
        value={price.gross}
        disabled
      />
    </Flex>
  );
};

export default CoursePrice;
