import React from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import CourseImageWrapper from './CourseImageWrapper';
import CourseCardWrapper from './CourseCardWrapper';
import CourseInfoWrapper from './CourseInfoWrapper';
import { Flex, Typo, Button, Figure } from '..';
import { media } from '../../utils/media';
// import theme from '../theme';

const ImagePlaceholder = styled.div`
  background-color: ${(props) => props.theme.colors.NEUTRAL_20};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

// const BundleTag = styled.div`
//   background-color: ${(props) => props.theme.colors.SECONDARY};
//   position: absolute;
//   top: 1.5rem;
//   left: 1.5rem;
//   padding:0.5rem;
//   border-radius: 4px;
// `

const ActionsWrapper = styled(Flex)`
  margin-top: 1.75rem;

  ${media.lessThan('tablet')} {
    margin-top: 1.5rem;
  }
`;

const DashboardCourseCard = ({
  courseInfo,
  goToCoursePage,
  goToEditCourse,
  inGrid,
  goToSettings

}) => {
  const { t } = useTranslation();

  const { coverImage, coverUrl } = courseInfo
  const imageUrl = !coverImage ? coverUrl : Array.isArray(coverImage) ? coverImage[0].url : coverImage?.url

  const clickOnCard = () => {
    goToCoursePage(courseInfo, courseInfo.type === 2)
  }

  const clickForEditCourse = (e) => {
    e.preventDefault()
    e.stopPropagation()
    goToEditCourse(courseInfo, courseInfo.type === 2)
  }

  const clickForSettings = (e) => {
    e.stopPropagation()
    goToSettings(courseInfo)
  }

  return (
    <CourseCardWrapper onClick={clickOnCard} inGrid={inGrid}>
      <CourseImageWrapper >
        <Figure.Rectangle>
          {!imageUrl
            ? <ImagePlaceholder />
            : <img src={imageUrl} alt="CourseImage" loading='lazy' />}
        </Figure.Rectangle>
      </CourseImageWrapper>
      <CourseInfoWrapper
        course={courseInfo}
        showProgress={!courseInfo.canEdit}
        showButtons
        ownerAvatar={courseInfo.ownerLogo}
        ownerColor={courseInfo.ownerColor}
      >
        <ActionsWrapper justify='space-between'>
          {courseInfo.canEdit ? <>
            <Button icon='Settings' $asLink onClick={clickForSettings}>{t('globals.editSettings')}</Button>
            <Button icon='Edit' onClick={clickForEditCourse} >{t('globals.editContent')}</Button>
          </> : <>
            <div>
              <Typo.SmallText>{courseInfo.expires}</Typo.SmallText>
              <Typo.SmallText>{t('globals.expirationDate')}</Typo.SmallText>
            </div>
            <Button icon='Student'>{t('globals.continueCourse')}</Button>
          </>
          }
        </ActionsWrapper>
      </CourseInfoWrapper>
    </CourseCardWrapper>
  );
};

export default DashboardCourseCard;
