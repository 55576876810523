import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { useNavigate } from 'react-router';
import ListCourses from '../../../components/ListCourses';
import { Section, Typo } from '../../../ui';
import { TOAST_ERROR } from '../../../utils/constants';

import { selectLayout } from '../courseSlice';
import { selectCourses } from '../../webshop/coursesSlice';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next';
// import Testimonies from '../../../components/Testimonies';
import { CONTENT_SMALL, media } from '../../../utils/media';
import CourseInPackageDetails from './CourseInPackageDetails';
import CtaButton from './CtaButton';
import { selectStoreName } from '../globalSlice';


const Wrapper = styled.div` 
  max-width: ${CONTENT_SMALL}px;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 5rem 0;

  ${media.lessThan('laptop')} {
    padding: 5rem 1.5rem;
  }

`
const MainDescription = styled(Typo.Text)`
  max-width:39.7rem;
  margin: 3rem 0;
`

const ViewCoursePackage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCourseModal, setShowCourseModal] = useState(null)
  const course = useSelector(selectLayout)
  const courses = useSelector(selectCourses)
  const cartItems = useSelector(selectCartItemsIds);
  const storeName = useSelector(selectStoreName);


  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item))
      navigate(`/store/${storeName}/checkout`);

    }
  }, [dispatch, cartItems, t, navigate, storeName]);

  const showPackageInfo = useCallback((course) => {
    setShowCourseModal(course)
  }, [])

  const closeModal = () => {
    setShowCourseModal(null)
  }

  const excludeActive = useCallback(() => courses.filter(item => item.id !== course.id && !!item.public), [courses, course])();

  const {
    introduction,
    // testimonies,
    packageItems
  } = course;

  if (course.status !== 'succeeded' || !course) return null

  return (
    <>
      <Section size={CONTENT_SMALL} >
        <Typo.PageHeader>{introduction?.title}</Typo.PageHeader>
        {introduction?.text && <MainDescription $textAlign='center' >{introduction?.text}</MainDescription>}
      </Section>
      {packageItems?.length > 1 &&
        <Wrapper>
          <Typo.ContentBigHeader $marginBottom='3rem'>{t('webshop.course.coursesIncluded')}</Typo.ContentBigHeader>
          <ListCourses showProgress={false} showButtons={false} items={packageItems} showPackageInfo={showPackageInfo} />
          <CtaButton courseId={course.id} />
        </Wrapper>
      }
      {/* {!!Object.keys(highlightedInfo).length && <Section>
        <HighlightedInfo info={highlightedInfo} hasBottomMargin infoIcons={infoIcons} courseId={course.id} />
        <CtaButton courseId={course.id} />
      </Section>}
      <InfoPointsSection items={infoPoints} title={t('webshop.course.infoPointsTitle')} /> */}
      {/*{!!testimonies?.length && <Testimonies items={testimonies} />}*/}
      {!!excludeActive.length &&
        <Section title={t('webshop.course.relatedCourses')}>
          <ListCourses showProgress={false} showButtons={true} items={excludeActive} addItem={addItem} />
        </Section>
      }
      <CourseInPackageDetails onCloseModal={closeModal} course={showCourseModal} />
      <CtaButton courseId={course.id} />
    </>
  );
};

export default ViewCoursePackage;
