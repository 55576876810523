import React from 'react';
import styled from 'styled-components'
import { media } from '../../../../utils/media';
import CartItem from '../Cart/CartItem';

const CheckoutCartItemsContainer = styled.div`
  max-height: calc(100vh - 279px);
  overflow-y: auto;
  margin-top:20px;

  ${media.lessThan('tablet')} {
    border-top: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  }
`;

const CartItemsList = ({ cartItems, storeHideVat, noDelete }) => {

  return (
    <CheckoutCartItemsContainer>
      {cartItems.map((item, i) => item && <CartItem key={i} info={item} hideVAT={storeHideVat} noDelete={noDelete} />)}
    </CheckoutCartItemsContainer>
  );
};

export default CartItemsList;
