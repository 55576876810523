import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Textinput, SearchSelect, Modal, Figure, Flex } from '../../../ui';
import { selectCourses } from '../../webshop/coursesSlice';
import CourseImageWrapper from '../../../ui/CourseCard/CourseImageWrapper';
import ImageUploader from '../../media/uploaders/ImageUploader';
import { createCoursePackage, saveCourse } from '../../webshop/courseSlice';
import { createCourse, selectCourseDurations } from '../courseSlice';
import UcpPeriodSelector from '../../../components/UcpPeriodSelector';
import { getPeriodByTime } from '../../../utils/ucpPeriods';
import CoursePrice from './CoursePrice';


const CreateCourseModel = ({ open, course, isPackage, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);
  const [selectedCourseArray, setSelectedCourseArray] = useState([]);
  const [duration, setDuration] = useState({});
  const [price, setPrice] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [packageName, setPackageName] = useState('');
  const ucpPeriod = getPeriodByTime(course?.ucpPeriod);
  const courses = useSelector(selectCourses);
  const courseDurations = useSelector((state) => selectCourseDurations(state, true));

  const descriptionRef = useRef(null);

  const closeModal = () => {
    setCourseInfo(null);
    setSelectedCourseArray([]);
    setIsModalOpen(false);
    onClose();
  }

  useEffect(() => {
    course?.price ? setPrice(course?.price) : setPrice({ net: 0, gross: 0, discounted: 0 })
    setCourseInfo(course);
    setPackageName(course?.name);
    setIsModalOpen(open);
    course?.courses && setSelectedCourseArray(course?.courses);
  }, [open, course])

  useEffect(() => {
    if (isPackage) {
      setIsDisabled(!(selectedCourseArray?.length > 0 && packageName?.length > 0))
    } else {
      setIsDisabled(!packageName?.length > 0)
    }
  }, [selectedCourseArray, packageName, course, isPackage])

  const changePackageName = (e) => {
    setPackageName(e.target.value);
  }

  const handleCourseSelectionArray = (value) => {
    setSelectedCourseArray(value)
  }

  const saveImage = useCallback(image => {
    if (image) setCourseInfo({ ...courseInfo, coverImage: image });
  }, [courseInfo])

  const handleCreateCourse = () => {
    let courseIdsList;
    let newData;
    if (isPackage) {
      courseIdsList = selectedCourseArray.map(course => course.id)
      newData = {
        id: course && course.id,
        fileId: courseInfo?.coverImage.id,
        name: packageName,
        description: descriptionRef.current && descriptionRef.current.value,
        courses: courseIdsList,
        ucpPeriod: duration.time ? duration.time : ucpPeriod.time,
        price: price.hasOwnProperty('net') ? price.net : price,
      }
    } else {
      newData = {
        id: course && course.id,
        coverImage: courseInfo?.coverImage,
        name: packageName,
      }
    }

    course ? dispatch(saveCourse({ newData, isPackage }))
      : isPackage ? dispatch(createCoursePackage({ newData }))
        : dispatch(createCourse({ name: newData.name, coverImage: newData.coverImage }))
    closeModal()
  };

  const imageUrl = !!courseInfo?.coverImage && (courseInfo?.coverImage?.url || courseInfo?.coverImage[0]?.url)

  return (
    <Modal
      open={isModalOpen}
      showActions
      acceptIsDisabled={isDisabled}
      onAccept={handleCreateCourse}
      acceptLabel={t('globals.save')}
      onClose={closeModal}
      title={isPackage ? !course ? t('course.createNewPackage') : t('course.editNewPackage') : t('course.createNewCourse')}
      width='650px'
    >
      <Flex direction='column' gap='10' align='flex-start'>
        <CourseImageWrapper >
          <ImageUploader
            type={'single'}
            id={'courseEdit'}
            saveAction={saveImage}
            imageToPreview={imageUrl}
            label={t('webshop.addCoverImage')}
            iconName='Add'
          >
            {imageUrl && <Figure.Rectangle><img alt={'imageToPreview'} src={imageUrl} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </CourseImageWrapper>
        <Textinput
          label={isPackage ? t('course.packageName') : t('course.courseName')}
          propName={'name'}
          onChange={changePackageName}
          value={packageName}
        />
        {isPackage && <UcpPeriodSelector
          duration={duration?.label ? duration : ucpPeriod}
          options={courseDurations}
          setDuration={setDuration}
        />}
        {isPackage && price && <CoursePrice price={price} setPrice={setPrice} />}
        {(isPackage || course) && <Textinput
          label={t('globals.description')}
          propName={'description'}
          defaultValue={courseInfo?.description}
          innerRef={ref => descriptionRef.current = ref}
        />}
        {isPackage && <SearchSelect
          id="add-course-to-package"
          setValue={handleCourseSelectionArray}
          selectedOptions={selectedCourseArray}
          options={courses}
          label={t('invites.selectCourses')}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          multiple
        />}
      </Flex>
    </Modal>
  );
};

export default CreateCourseModel;
