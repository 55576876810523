import React from 'react';

const IframeEmbedder = (data, id = '') => {

    return (
        <iframe
          // className={this.props.className}
          // src={url + id + this.props.urlSuffix}
          src={data.url + id}
          frameBorder={0}
          scrolling={'no'}
          allowFullScreen
          title='embed'
        />
    )
};

export default IframeEmbedder;
