import React from 'react';
import { truncate } from '../../../helpers/stringHelpers';
import { Flex } from '../../../ui';
import DownloadableFile from '../../../ui/DownloadableFile';

const DownloadableAttach = ({ files }) => {

  return (
    !!files?.length &&
    <Flex direction={'column'} align={'baseline'}>
      {files.map((file, index) =>
        <Flex align={'center'} gap={10} key={`${file.id}-${index}`}>
          <DownloadableFile.AsAttach label={truncate(file.name, 50)} file={file}/>
        </Flex>
      )}
    </Flex>
  );
};
export default DownloadableAttach;
