import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { Page, Loading, Flex } from '../../../../ui';
import { fetchPageById, markPageAsViewed, selectPage, selectPageStatus } from './coursePageSlice';
import { STATUS_SUCCEEDED, coursePageNavigation } from '../../../../utils/constants';
import { selectCourseTheme } from '../../../course/courseSlice';
import ModuleSwitcher from './ModuleSwitcher';
import { useNewViewCourseId } from '../../../../hooks/readFromUrls';

const RowModules = styled(Flex)` 
  &:first-of-type{
    margin-top:30px;
  }
  //hide empty components so we dont have extra empty space
  &:empty{
    display:none;
  }
`

const CoursePage = ({pageId}) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(selectPage);
  const pageStatus = useSelector(selectPageStatus)
  const courseTheme = useSelector(selectCourseTheme)
  const courseId = useNewViewCourseId();

  useEffect(() => {
    pageId && dispatch(fetchPageById({pageId})).then(() => {
      dispatch(markPageAsViewed({pageId, courseId}))
    });
  }, [pageId, dispatch, courseId]);

  return (
    pageStatus === STATUS_SUCCEEDED && !isEmpty(pageInfo) ?
      <Page substractBottom={coursePageNavigation} gap='40'>
        {pageInfo.pageRows.map(pageRow => {
          return <RowModules gap='20' maxWidth='90%' width='100%' key={pageRow.id} justify='center' align='flex-start'>
            {pageRow.pageModules.map(module =>{
              return <ModuleSwitcher columns={pageRow.pageModules.length} key={module.id} module={module} theme={courseTheme} />
            })}
          </RowModules>
        })}
      </Page>
    : <Loading />
  );
};

export default CoursePage;
