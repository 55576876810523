import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components'
import { Typo, Loading, Flex, Dropdown, Icon, Button } from '../../../ui';
import { useDispatch, useSelector } from 'react-redux';
import { fileUpload, imageUpload, selectFile, selectFileStatus } from '../../fileUploader/fileSlice';
import MediaPicker from '../MediaPicker';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';

const FileLoadingWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
`

export const UPLOADER_TYPE = {VIDEO:'video', IMAGE:'image', FILE:'file'}

const FileUploader = ({
  saveAction,
  uploaderType = UPLOADER_TYPE.FILE,
  id,
  type,
  accept,
  variant,
  bgcolor,
  fileType,
  label,
  iconName = 'AttachFile',
  noMediaLibrary
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [file, setFile] = useState(null);

  // const file = useSelector(selectFile);
  const fileStatus = useSelector(selectFileStatus);
  const [libraryModal, setLibraryModal] = useState(false);

  const handleLibraryModal = useCallback((e) => {
    e.stopPropagation();
    setLibraryModal(!libraryModal);
  }, [libraryModal]);

  const chooseFile = () => {
    document.getElementById(id).value = null;
    document.getElementById(id).click();
  };

  useEffect(() => {
    if (file) {
      saveAction({ id: file.id, url: file.url, name: file.name });
      dispatch({ type: 'clearFile' });
    }
  }, [file])// eslint-disable-line react-hooks/exhaustive-deps

  //TODO: figure out why we need this stuff
  // useEffect(() => {
  //   function onCompDestroy() {
  //     saveAction(null);
  //     dispatch({ type: 'clearFile' });
  //   }
  //   onCompDestroy();
  // }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFile = useCallback(e => {
    const selectedFile = e.target.files[0];
    switch (uploaderType) {
      case UPLOADER_TYPE.IMAGE:
        dispatch(imageUpload({ file: selectedFile, mockedType: type })).then(action => {
          setFile(action.payload);
        })
        break;
      case UPLOADER_TYPE.VIDEO:
        dispatch(fileUpload(selectedFile)).then(action => {
          //TODO: setFile according video response
          console.log('VIDEO', action)
        });
        break;
      default:
        dispatch(fileUpload(selectedFile)).then(action => {
          setFile(action.payload[0].file);
        });
        break;
    }
  }, [dispatch, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const LoadingWrapper = () =>
    <FileLoadingWrapper>
      <Loading />
    </FileLoadingWrapper>;

  const EditFile = () =>
    <Flex align='flex-start' direction='column'>
      <input accept={accept} type='file' style={{ display: 'none' }} id={id} onChange={onSelectFile} />

      {!noMediaLibrary ? <Dropdown
        label={label || t('globals.attachFile')}
        iconName={iconName}
        variant={variant}
        bgcolor={bgcolor}
      >
        <ListItem  disablePadding divider>
          <ListItemButton htmlFor={id} onClick={chooseFile} dense>
            <ListItemIcon><Icon.Devices size='small' /></ListItemIcon>
            <Typo.Text>{t('mediaLibrary.uploadFromComp')}</Typo.Text>
          </ListItemButton>
        </ListItem>
        <ListItem  disablePadding >
          <ListItemButton onClick={handleLibraryModal} dense>
            <ListItemIcon><Icon.Media size='small'/></ListItemIcon>
            <Typo.Text>{t('mediaLibrary.uploadFromMedia')}</Typo.Text>
          </ListItemButton>
        </ListItem>
      </Dropdown>
      : <Button
        $variant={variant}
        $bgcolor={bgcolor}
        icon={iconName}
        htmlFor={id}
        onClick={chooseFile} >{label || t('globals.attachFile')}</Button>
      }
    </Flex>

  return (
    fileStatus === 'fileLoading' ? <LoadingWrapper />
      : <>
          <EditFile />
          <MediaPicker
            saveAction={saveAction}
            mediaType={fileType || 'all'}
            isOpen={libraryModal}
            closeModal={handleLibraryModal}
          />
        </>
  )
};

export default FileUploader;
