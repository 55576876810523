import colors from './colors'
import fonts from './fonts'
import shadows from './shadows'

const theme = {
  colors,
  fonts,
  shadows,
  fontSizes: {
    h1: '34px',
    h2: '30px',
    h3: '24px',
    h4: '20px',
    h5: '1.125rem',

    regular: '1.125rem', //18px
    medium: '1rem', //16px
    small: '0.875rem', //14px
    xSmall: '0.75rem', //12px

  },
  headerSize: '75px',
  footerSize: '100px'

}
export default theme;
