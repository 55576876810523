import React from 'react'
import styled from 'styled-components'

import { CloudUpload } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { ReactComponent as BarChart } from './svg/chart.svg';
import { ReactComponent as Books } from './svg/book.svg';
import { ReactComponent as BulletedList } from './svg/bulletlist.svg';
import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as Diploma } from './svg/diploma.svg';
import { ReactComponent as Check } from './svg/check.svg';
import { ReactComponent as Download } from './svg/download.svg';
import { ReactComponent as File } from './svg/file.svg';
import { ReactComponent as Fire } from './svg/fire.svg';
import { ReactComponent as Flash } from './svg/flash.svg';
import { ReactComponent as Food } from './svg/food.svg';
import { ReactComponent as Group } from './svg/group.svg';
import { ReactComponent as Claps } from './svg/handclaps.svg';
import { ReactComponent as Health } from './svg/health.svg';
import { ReactComponent as Idea } from './svg/idea.svg';
import { ReactComponent as Image } from './svg/image.svg';
import { ReactComponent as Invitation } from './svg/invitations.svg';
import { ReactComponent as Kpi } from './svg/kpi.svg';
import { ReactComponent as Money } from './svg/money.svg';
import { ReactComponent as Nature } from './svg/nature.svg';
import { ReactComponent as Notepad } from './svg/note.svg';
import { ReactComponent as Online } from './svg/online.svg';
import { ReactComponent as Pen } from './svg/pen.svg';
import { ReactComponent as Profile } from './svg/profile.svg';
import { ReactComponent as Professor } from './svg/teacher.svg';
import { ReactComponent as ProsCons } from './svg/proscons.svg';
import { ReactComponent as Puzzle } from './svg/puzzle.svg';
import { ReactComponent as Questions } from './svg/questions.svg';
import { ReactComponent as Settings } from './svg/settingw.svg';
import { ReactComponent as SpellCheck } from './svg/spellcheck.svg';
import { ReactComponent as Star } from './svg/star.svg';
import { ReactComponent as StepByStep } from './svg/stepbystep.svg';
import { ReactComponent as Student } from './svg/student.svg';
import { ReactComponent as Repeat } from './svg/repeat.svg';
import { ReactComponent as Clock } from './svg/time.svg';
import { ReactComponent as Tree } from './svg/tree.svg';
import { ReactComponent as Video } from './svg/video.svg';
import { ReactComponent as Certified } from './svg/certified.svg';
import { ReactComponent as Appartment } from './svg/appartment.svg';
import { ReactComponent as Baking } from './svg/baking.svg';
import { ReactComponent as Bidding } from './svg/bidding.svg';
import { ReactComponent as Chef } from './svg/chef.svg';
import { ReactComponent as Contract } from './svg/contract.svg';
import { ReactComponent as Cooking } from './svg/cooking.svg';
import { ReactComponent as Curtains } from './svg/curtains.svg';
import { ReactComponent as Gift } from './svg/gift.svg';
import { ReactComponent as House } from './svg/house.svg';
import { ReactComponent as Sofa } from './svg/sofa.svg';

// Deprecated (eventually remove it):
import { ReactComponent as ClockForward } from './svg/Clock-Forward.svg';
import { ReactComponent as FilePictures } from './svg/File-Pictures.svg';
import { ReactComponent as Lections } from './svg/Lections.svg';
import { ReactComponent as LetterOpen } from './svg/Letter-Open.svg';
import { ReactComponent as Statistic } from './svg/Statistic.svg';
import { ReactComponent as StudentHat } from './svg/Student-Hat.svg';
import { ReactComponent as TextHighlight } from './svg/Text-HighlightColor.svg';
import { ReactComponent as User } from './svg/User.svg';
import { ReactComponent as VideoFour } from './svg/Video-4.svg';
import { ReactComponent as VideoFive } from './svg/Video-5.svg';
import { getUserThemeContrastColor } from '../theme/colors';

const symbolSize = { 
  'x-small': {value:'30px', adjustment:'0px'}, 
  'small': {value:'45px', adjustment:'0px'}, 
  'medium': {value:'49px', adjustment:'5px'}, 
  'large': {value:'75px', adjustment:'5px'}, 
  'gigantic': {value:'77px', adjustment:'5px'} 
}

const SvgWrapper = styled.i`
  display: inline-block;
  svg {
    width: ${({ size }) => symbolSize[size]?.value || symbolSize['x-small'].value};
    height: ${({ size }) => `calc(${symbolSize[size]?.value || symbolSize['x-small'].value} - ${symbolSize[size]?.adjustment || symbolSize['x-small'].adjustment})`};
    fill:${({ color, $withBgColor }) => $withBgColor ? getUserThemeContrastColor($withBgColor) : color};
    padding-top:4px;
  }
  svg path {
      stroke: ${({ color, $withBgColor=true }) => $withBgColor ? getUserThemeContrastColor($withBgColor) : color};
      fill: ${({ color, $usefill, $withBgColor=true }) => $usefill && $withBgColor ? getUserThemeContrastColor($withBgColor) : color};
  }
  background-color:${({ $withBgColor }) => $withBgColor || 'none'};
  border-radius: ${({ size }) => symbolSize[size]?.value || symbolSize['x-small'].value};
`
const wrapIcon = (Component, usefill = true) => props => (
  <SvgWrapper {...props} $usefill={usefill}>
    <Component />
  </SvgWrapper>
)

const Symbols = {
  BarChart: wrapIcon(BarChart),
  Books: wrapIcon(Books),
  BulletedList: wrapIcon(BulletedList),
  Calendar: wrapIcon(Calendar),
  Claps: wrapIcon(Claps),
  Clock: wrapIcon(Clock),
  Check: wrapIcon(Check),
  Diploma: wrapIcon(Diploma),
  Download: wrapIcon(Download),
  File: wrapIcon(File),
  Fire: wrapIcon(Fire),
  Flash: wrapIcon(Flash),
  Food: wrapIcon(Food),
  Group: wrapIcon(Group),
  Health: wrapIcon(Health),
  Idea: wrapIcon(Idea),
  Image: wrapIcon(Image),
  Kpi: wrapIcon(Kpi),
  Money: wrapIcon(Money),
  Nature: wrapIcon(Nature),
  Notepad: wrapIcon(Notepad),
  Pen: wrapIcon(Pen),
  Professor: wrapIcon(Professor),
  Profile: wrapIcon(Profile),
  ProsCons: wrapIcon(ProsCons),
  Puzzle: wrapIcon(Puzzle),
  Questions: wrapIcon(Questions),
  Settings: wrapIcon(Settings),
  Online: wrapIcon(Online),
  SpellCheck: wrapIcon(SpellCheck),
  Star: wrapIcon(Star),
  StepByStep: wrapIcon(StepByStep),
  Student: wrapIcon(Student),
  Tree: wrapIcon(Tree),
  Video: wrapIcon(Video),
  Invitation: wrapIcon(Invitation),
  Upload: wrapIcon(CloudUpload),
  Loading: CircularProgress,
  Certified: wrapIcon(Certified),
  Appartment: wrapIcon(Appartment),
  Baking: wrapIcon(Baking),
  Bidding: wrapIcon(Bidding),
  Chef: wrapIcon(Chef),
  Contract: wrapIcon(Contract),
  Cooking: wrapIcon(Cooking),
  Curtains: wrapIcon(Curtains),
  Gift: wrapIcon(Gift),
  House: wrapIcon(House),
  Sofa: wrapIcon(Sofa),


  //Deprecated
  ClockForward: wrapIcon(ClockForward),
  FilePictures: wrapIcon(FilePictures),
  Lections: wrapIcon(Lections, false),
  LetterOpen: wrapIcon(LetterOpen),
  Repeat: wrapIcon(Repeat),
  Statistic: wrapIcon(Statistic),
  StudentHat: wrapIcon(StudentHat),
  TextHighlight: wrapIcon(TextHighlight),
  User: wrapIcon(User),
  VideoFour: wrapIcon(VideoFour),
  VideoFive: wrapIcon(VideoFive),
};

export default Symbols;
