import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import {
  selectPrivacyAcceptance,
  setPrivacyAcceptance,
  setPaymentEmail,
  selectPaymentEmail,
  startGuestPaymentProcess,
  startPaymentProcess,
  selectIsAGift,
  selectPaymentStatus
} from '../../paymentsSlice';
import { selectCountry, selectDiscountCode, selectPaymentType, selectStoreName, setPaymentType } from '../../globalSlice';
import { selectAuthState, selectUser } from '../../../../features/auth/authSlice';
import { Typo, Button, Flex } from '../../../../ui';
import { STATUS_LOADING, currenciesByCountry } from '../../../../utils/constants';
import NotLoggedInInfo from './NotLoggedInInfo';
import { media } from '../../../../utils/media';

const GotoPayments = styled(Flex)`
  margin-top:1rem;
`

const CheckoutInfoWrapper = styled.div`
  width: 60%;
  background-color: ${(props) => props.theme.colors.NEUTRAL_0};
  padding: 1rem 2rem;

  ${media.lessThan('tablet')} {
    width: 100%;
    padding: 1rem 0;
  }
`;

const CheckoutInfo = ({ cartItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector(selectAuthState);
  const storeName = useSelector(selectStoreName);
  const isAGift = useSelector(selectIsAGift);
  const privacyAcceptance = useSelector(selectPrivacyAcceptance);
  const paymentEmail = useSelector(selectPaymentEmail);
  const user = useSelector(selectUser);
  const paymentStatus = useSelector(selectPaymentStatus);
  const paymentType = useSelector(selectPaymentType);
  const discountCode = useSelector(selectDiscountCode);
  const country = useSelector(selectCountry);

  useEffect(() => {
    if (user) {
      dispatch(setPaymentEmail(user.email))
      dispatch(setPrivacyAcceptance(true))
    }
  }, [user, dispatch])

  useEffect(() => {
    if (cartItems[0].currency !== currenciesByCountry[country]) {
      dispatch(setPaymentType(6));
    }
  }, [cartItems, country, dispatch])

  const startPaymentSession = useCallback(() => {
    const gifterEmail = !!isAGift ? paymentEmail : null;

    if (paymentStatus !== STATUS_LOADING) {
      if (authState.loggedIn) {
        dispatch(startPaymentProcess({ providerId: paymentType, storeName, cart: cartItems, gifterEmail, discountCode, country }))
      } else {
        dispatch(startGuestPaymentProcess({ providerId: paymentType, storeName, cart: cartItems, gifterEmail, targetEmail: paymentEmail, discountCode, country }))
      }
    }

  }, [authState, cartItems, dispatch, country, discountCode, paymentEmail, storeName, isAGift, paymentStatus, paymentType]);

  const gotToProfile = useCallback(() => {
    navigate('/profile/info');
  }, [navigate]);

  return (
    <CheckoutInfoWrapper>
      {!authState.loggedIn ?
        <NotLoggedInInfo />
        :
        <Flex direction='column' gap='5' align='flex-start'>
          <Typo.TextTitle>{t('webshop.checkout.myInfo')}</Typo.TextTitle>
          <Typo.Text>{t('globals.email')}: {user.email}</Typo.Text>
          <Button $asText onClick={gotToProfile}>{t('webshop.checkout.editMyInfo')}</Button>
        </Flex>
      }

      {<GotoPayments width='100%' justify='right'>
        <Button width='100%' $variant='action' disabled={!privacyAcceptance || !paymentEmail} onClick={startPaymentSession}>{t('webshop.checkout.goToPayment')}</Button>
      </GotoPayments>}
    </CheckoutInfoWrapper>

  );
};

export default CheckoutInfo;

