import React from 'react';
import styled from 'styled-components'
import MediaPlayer from '../MediaPlayer';
import { CONTENT_STANDARD } from '../../utils/media';
import Figure from '../Figure';
import { getUserThemeColor } from '../theme/colors';
import { imageChecker } from '../../helpers/imageChecker';

const EmptyCoverMedia = styled.div` 
  background-color: ${(props) => props.$themeColor};
  height: 2.5rem;
`
const ImageWrapper = styled.div` 
  max-width: ${`${CONTENT_STANDARD}px`};
  margin: auto;
`

const CoverSection = styled.section`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$themeColor};
    backdrop-filter: blur(50px);
  }
`;

const CoverWrapper = styled.div`
  overflow:hidden;
  max-width: ${CONTENT_STANDARD}px;
  width: 100%;
  margin: 0 auto;
`

const CoverMedia = ({
  file,
  videoPreview,
  themeColor,
  thumbnailImage,
}) => {

  // const [thumbnail, setThumbnail] = useState();

  //Need to use vimeo API for vimeo thumbnails
  // const fetchVimeoThumbnail = async (videoId) => {
  //   const res = await fetch('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + videoId);
  //   const data = await res.json();
  //   return data.thumbnail_url;
  // }

  // const createThumbnailLink = useCallback(async (video) => {
  //   const ytRegEx = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(watch\?))\??v?=?([^#&?]*).*/;
  //   const vimeoRegEx = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  //   const youtubeMatch = video?.match(ytRegEx);
  //   const vimeoMatch = video?.match(vimeoRegEx)
  //   let thumbnailLink;

  //   if (youtubeMatch) {
  //     thumbnailLink = 'https://i.ytimg.com/vi/' + youtubeMatch[6] + '/maxresdefault.jpg'
  //   } else if (vimeoMatch) {
  //     thumbnailLink = await fetchVimeoThumbnail(vimeoMatch[5]);
  //   } else {
  //     thumbnailLink = video.replace(".mp4", "/frame_0001.jpg");
  //   }
  //   setThumbnail(thumbnailLink);
  // }, [])

  // useEffect(() => {
  //   if (videoPreview) createThumbnailLink(videoPreview);
  // }, [videoPreview, createThumbnailLink])

  // if (!videoPreview && !file) return null

  // video can be a url or video player content (youtube/vimeo)
  // then it can be in builder mode
  // or an image

  const color = getUserThemeColor(themeColor)

  return (
    (!videoPreview && !file && !imageChecker(thumbnailImage)) ?
      <EmptyCoverMedia $themeColor={color} />
      : <CoverSection $themeColor={color} >
        {imageChecker(thumbnailImage) && !videoPreview && <ImageWrapper size={CONTENT_STANDARD}><Figure.Rectangle $maxHeight='660px'><img alt={'cover'} src={imageChecker(thumbnailImage)} loading='lazy' /></Figure.Rectangle></ImageWrapper>}
        {videoPreview && <CoverWrapper>
          <Figure.Video>
            <MediaPlayer posterImage={imageChecker(thumbnailImage)} previewContent={videoPreview} width="100%" height="auto" />
          </Figure.Video>
        </CoverWrapper>}
      </CoverSection>
  );
};

CoverMedia.displayName = 'CoverMedia'

export default CoverMedia;
