import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MediaPlayer from '../../../ui/MediaPlayer';
import { Button, Figure, Typo, Modal, Flex } from '../../../ui';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { media } from '../../../utils/media';
import { deleteFile, getFileCourseInfo, selectFileById } from '../mediaSlice';
import { useTranslation } from 'react-i18next';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DownloadableFile from '../../../ui/DownloadableFile';

const Image = styled(Figure.Landscape)`
  background: repeating-conic-gradient(${props => props.theme.colors.NEUTRAL_LIGHT} 0% 25%, transparent 0% 50%) 0% / 25px 25px;
  img { object-fit: contain; }
`;

const MyDocViewer = styled(DocViewer)`
  min-height:600px;
  ${media.lessThan('phablet')} {
    max-height:440px;
  }
`;

const FileContent = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:10px;
    align-items: center;
  }
`;

const FileDetails = ({ fileId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [usedInCourses, setUsedInCourses] = useState(null);
  const urlSplit = location.pathname.split('/')
  const id = urlSplit[3];
  const file = useSelector((state) => selectFileById(state, id));

  useEffect(()=>{
    fileId && dispatch(getFileCourseInfo({ id: fileId}))
      .then(res => setUsedInCourses(res.payload))

  }, [fileId])

  const closeModal = useCallback(() => {
    navigate(-1)
  }, [navigate]);

  const handleDeleteDialog = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen]);

  const onDeleteFile = useCallback(() => {
    dispatch(deleteFile({ id: file.id })).then((res) => {
      res.payload && closeModal()
    })
    setDialogOpen(!dialogOpen)
  }, [dispatch, file, setDialogOpen, dialogOpen, closeModal]);

  return (
    <Modal
      open={!!fileId}
      width='1100px'
      maxWidth='lg'
      onClose={closeModal}
      dialogActionsChildren={<>
        <FileContent>
          <Flex direction={'column'} align={'flex-start'}>
            <Typo.Text><strong>{t('globals.name')}:</strong> {file?.name}</Typo.Text>
            <Typo.Text><strong>{t('globals.created')}:</strong> {file?.created}</Typo.Text>
            <Typo.Text><strong>{t('globals.size')}:</strong> {(file?.size / 1000000).toFixed(4)}MB</Typo.Text>
            {/* <Typo.Text><strong>In courses:</strong> {usedInCourses?.length ? usedInCourses?.toString() : 'none'}</Typo.Text> */}
          </Flex>
          <Flex gap='20'>
            <DownloadableFile.AsButton label={t('globals.download')} file={file}/>
            <Button icon='Delete' onClick={handleDeleteDialog}>{t('globals.delete')}</Button>
          </Flex>
        </FileContent>

        <ConfirmDelete
          open={dialogOpen}
          title={t('mediaLibrary.deleteConfirmationTitle')}
          description={t('mediaLibrary.deleteConfirmationDescription')}
          onClose={handleDeleteDialog}
          onAcceptDelete={onDeleteFile}
        /></>}
    >
      {file?.type === 'video' && <Figure.Video> <MediaPlayer previewContent={file?.url} /> </Figure.Video>}
      {file?.type === 'audio' && <MediaPlayer width='100%' height='100px' previewContent={file?.url} isAudio={true} />}
      {file?.type === 'image' && <Image> <img alt={'file'} id={file?.id} src={file?.url} loading='lazy' /> </Image>}
      {file?.type === 'other' &&
        <MyDocViewer
          documents={[{ uri: file.url, fileType: file.mime }]}
          prefetchMethod={'GET'}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
          pluginRenderers={DocViewerRenderers}
        />
      }
    </Modal >
  )
}

export default FileDetails;
